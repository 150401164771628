import React, { useEffect, useState, useRef } from 'react';
import './css/style.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuClick = () => {
    setMenuOpen(prevState => !prevState);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <header>
      {/* <a href="/" className="brand-logo">DubaiServer Diablo II</a> */}
      <div ref={menuRef} className={`toggle ${menuOpen ? 'open' : ''}`}>
        <div className="toggle-button" onClick={handleMenuClick}>
          <div className="toggle-button__line" id="effect-1"></div>
          <div className="toggle-button__line"></div>
          <div className="toggle-button__line" id="effect-2"></div>
        </div>
        <ul className="nav">
          <li><a href="/" >Inicio</a></li>
          <li><Link to="/donaciones">Donaciones</Link></li>
          <li><a href="https://www.youtube.com/@DubaiServerDiabloII/videos" target="_blank" rel="noreferrer">Guia de Instalación</a></li>
          {/* <li><a href="https://tiny.cc/ModDubai" target="_blank" rel="noreferrer">Detalle de Cambios</a></li> */}
          <li><Link to="/cambios">Detalle de Cambios</Link></li>
          <li><Link to="/breakpoints">Breakpoints Fcr/Fhr/Ias/Fbr/Frw</Link></li>
          <li><a href="https://tiny.cc/ReglasPvP" target="_blank" rel="noreferrer">Reglas PvP</a></li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
