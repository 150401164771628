import React from 'react';
import './css/style.css';

const Content = () => {
  return (
    <div className="main-container">
    <div className="content-description">
      <div className="content-effect-1">
        <p>Nivelación exclusiva diseñada por la comunidad.</p>
        <p>Modo Full HD 60 FPS.</p>
        <p>Drop Aumentado, Exp Aumentada.</p>
        <p>Nuevo sistema Cofre de Items.</p>
        <p>Nueva Bolsa de Runas.</p>
        <p>Arena PvP y Liga PvP.</p>
        <p>Próximo Reset: Viernes 28 de Febrero 21:30 Hs ARG.</p>
        {/* <p>Descarga el juego:</p>
        <p><Link to="/cambios">Click para ver Cambios</Link></p> */}


        </div>
        {/* <div className="content-effect-2">
        <h2>Listos, Preparados, Ya!</h2>
        <p>aca texto</p>
        <a href="https://tiny.cc/DubaiPvM">Aprender más</a>
      </div> */}
        <div className="link-descargas">
        <a href="https://tiny.cc/DubaiPvM" id="linkbox">Descargar Juego</a>
        <p></p>
        {/* <a href="https://tiny.cc/DubaiPvP" id="linkbox">Descargar Server PvP</a> */}
        <a href="https://dubai-server.com/cambios" id="linkbox">Ver Cambios</a>
      </div>
      </div>
    </div>
  );
};

export default Content;
